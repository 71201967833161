<template>
  <v-dialog
    v-model="show"
    width="400px"
    @click:outside="cancel()"
    @keydown.esc="cancel()"
  >
    <v-card>
      <v-card-title class="justify-center">
        <span class="headline">Admin deleting</span>
      </v-card-title>
      <v-card-text class="text-center">
        <span>Are you sure want to delete admin with id {{ id }}?</span>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="cancel()">
          Cancel
        </v-btn>
        <v-btn @click="submit()">
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

import http from '../../../services/http'

export default {
  name: 'DeleteDialog',
  props: {
    show: Boolean,
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      id: undefined
    }
  },
  watch: {
    item(value) {
      this.id = value.id
    }
  },
  methods: {
    reset(event) {
      this.$emit(event)
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    cancel() {
      this.reset('cancel')
    },
    submit() {
      http.delete(`api/v1/admins/${this.id}`, this.model)
        .then(
          () => {
            this.pushMessage({
              text: 'Admin was deleted successfully',
              color: 'green'
            })
            this.reset('submit')
          },
          error => {
            this.pushMessage({
              text: error.response.data.message || 'Failed to delete admin',
              color: 'red'
            })
          }
        )
    },
    ...mapActions([
      'pushMessage',
    ])
  }
}
</script>
