<template>
  <v-app>
    <v-main>
      <v-container
        fill-height
        fluid
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            md4
            shrink
          >
            <v-card>
              <v-toolbar>
                <v-toolbar-title>Sexologist Dictionary App</v-toolbar-title>
              </v-toolbar>

              <v-card-text>
                <validation-observer ref="form">
                  <v-form>
                    <validation-provider
                      v-slot="{ errors }"
                      name="username"
                      rules="required|max:100"
                    >
                      <v-text-field
                        v-model="model.username"
                        data-vv-name="username"
                        :error-messages="errors[0]"
                        label="Username"
                        prepend-icon="mdi-account"
                        type="text"
                      />
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      name="password"
                      rules="required|min:6|max:100"
                    >
                      <v-text-field
                        v-model="model.password"
                        data-vv-name="password"
                        :error-messages="errors[0]"
                        label="Password"
                        prepend-icon="mdi-lock"
                        type="password"
                      />
                    </validation-provider>
                  </v-form>
                </validation-observer>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn @click="login()">
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapActions } from 'vuex'

import http from '../services/http'

export default {
  name: 'Login',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider
  },
  data() {
    return {
      model: {
        username: null,
        password: null
      }
    }
  },
  methods: {
    login() {
      this.$refs.form.validate().then(isValid => {
        if (isValid) {
          http.post('api/v1/auth/login', this.model)
            .then(
              response => {
                if (response.headers.token) {
                  localStorage.setItem('token', response.headers.token)
                  this.pushMessage({
                    text: 'Login succeeded',
                    color: 'green'
                  })
                  this.$router.push('/').catch(error => console.error(error)) // eslint-disable-line no-console
                } else {
                  this.pushMessage({
                    text: 'Login failed',
                    color: 'red'
                  })
                }
              },
              error => {
                this.pushMessage({
                  text: error.response.data.message || 'Login failed',
                  color: 'red'
                })
                this.model = {}
                this.$refs.form.reset()
              }
            )
        }
      })
    },
    ...mapActions([
      'pushMessage'
    ])
  }
}
</script>
