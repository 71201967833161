<template>
  <v-app>
    <router-view />
    <v-snackbar
      v-for="message in messages"
      :key="message.id"
      v-model="message.text"
      :color="message.color"
      :timeout="-1"
      top
      right
    >
      {{ message.text }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'messages'
    ])
  }
}
</script>

<style lang="sass">
.v-btn:not(.v-btn--round).v-size--default
  min-width: 88px

.v-tooltip__content
  font-size: 12px
  padding: 3px 12px
</style>
