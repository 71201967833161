<template>
  <v-dialog
    v-model="show"
    width="800px"
    @click:outside="cancel()"
    @keydown.esc="cancel()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Dictionary Uploading</span>
      </v-card-title>
      <v-card-text>
        <validation-observer ref="form">
          <v-form>
            <validation-provider
              v-slot="{ errors }"
              name="file"
              rules="required|mimes:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
              <v-file-input
                v-model="model.file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                :error-messages="errors[0]"
                show-size
              />
            </validation-provider>
          </v-form>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel()">
          Cancel
        </v-btn>
        <v-btn @click="submit()">
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapActions } from 'vuex'

import http from '../../../services/http'

export default {
  name: 'UploadDialog',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider
  },
  props: {
    show: Boolean
  },
  data() {
    return {
      model: {
        file: undefined
      }
    }
  },
  methods: {
    reset(event) {
      this.$emit(event)
      Object.assign(this.$data, this.$options.data.apply(this))
      this.$refs.form.reset()
    },
    cancel() {
      this.reset('cancel')
    },
    submit() {
      this.$refs.form.validate().then(isValid => {
        if (isValid) {
          const data = new FormData()
          data.append('file', this.model.file)
          http.post('api/v1/words/upload', data, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(
              () => {
                this.pushMessage({
                  text: 'Dictionary was uploaded successfully',
                  color: 'green'
                })
                this.reset('submit')
              },
              error => {
                this.pushMessage({
                  text: error.response.data.message || 'Failed to upload dictionary',
                  color: 'red'
                })
                Object.assign(this.$data, this.$options.data.apply(this))
              }
            )
        }
      })
    },
    ...mapActions([
      'pushMessage',
    ])
  }
}
</script>
