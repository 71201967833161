import { isEqual } from 'lodash'
import Vue from 'vue'
import Vuex from 'vuex'

import http from '../services/http'

Vue.use(Vuex)

const entity = {
  data: [],
  pagination: {
    rowCount: 0
  }
}

const entityPagination = {
  page: 1,
  itemsPerPage: 10,
  sortBy: ['id'],
  sortDesc: [false]
}

const initialState = () => ({
  drawer: true,
  words: { ...entity },
  categories: { ...entity },
  admins: { ...entity },
  wordsPagination: { ...entityPagination, sortDesc: [true] },
  wordsFilters: {},
  categoriesPagination: { ...entityPagination },
  adminsPagination: { ...entityPagination },
  categoriesOptions: [],
  fmWords: [],
  pmWords: [],
  searchForm: { text: '' },
  user: null
})

const store = new Vuex.Store({
  state: {
    ...initialState(),
    messages: []
  },
  getters: {
    drawer: (state) => state.drawer,
    wordsItems: (state) => state.words.data,
    categoriesItems: (state) => state.categories.data,
    adminsItems: (state) => state.admins.data,
    wordsTotalItems: (state) => state.words.pagination.rowCount,
    categoriesTotalItems: (state) => state.categories.pagination.rowCount,
    adminsTotalItems: (state) => state.admins.pagination.rowCount,
    wordsPagination: (state) => state.wordsPagination,
    wordsFilters: (state) => state.wordsFilters,
    categoriesPagination: (state) => state.categoriesPagination,
    adminsPagination: (state) => state.adminsPagination,
    categoriesOptions: (state) => state.categoriesOptions,
    fmWords: (state) => state.fmWords,
    pmWords: (state) => state.pmWords,
    searchForm: (state) => state.searchForm,
    user: (state) => state.user,
    messages: (state) => state.messages
  },
  mutations: {
    switchDrawer: (state) => state.drawer = !state.drawer,
    initWords: (state, data) => state.words = { ...data },
    initCategories: (state, data) => state.categories = { ...data },
    initAdmins: (state, data) => state.admins = { ...data },
    initWordsPagination: (state, data) => state.wordsPagination = { ...data },
    initWordsFilters: (state, data) => state.wordsFilters = { ...data },
    initCategoriesPagination: (state, data) => state.categoriesPagination = { ...data },
    initAdminsPagination: (state, data) => state.adminsPagination = { ...data },
    initCategoriesOptions: (state, data) => state.categoriesOptions = data,
    initFmWords: (state, data) => state.fmWords = data,
    initPmWords: (state, data) => state.pmWords = data,
    initSearchForm: (state, data) => state.searchForm = { ...data },
    initUser: (state, data) => state.user = data,
    addMessage: (state, data) => state.messages.push(data),
    delMessage: (state, id) => state.messages.splice(state.messages.findIndex(message => message.id === id), 1),
    reset: (state) => Object.entries(initialState()).forEach(([key, value]) => state[key] = value)
  },
  actions: {
    initWords: ({ commit, state }, { pagination = false, filters = false } = {}) => {
      if (!state.words.data.length || !isEqual(pagination, state.wordsPagination) || !isEqual(filters, state.wordsFilters)) {
        const { page, itemsPerPage, sortBy: [sortBy], sortDesc: [sortDesc] } = pagination || state.wordsPagination
        http
          .get('api/v1/words', {
            params: {
              ...(filters || state.wordsFilters), page, pageSize: itemsPerPage, sort: sortBy && `${sortDesc ? '-' : ''}${sortBy}`, includes: ['category']
            }
          })
          .then(response => response.data)
          .then(data => {
            commit('initWords', data)
            commit('initWordsPagination', pagination || state.wordsPagination)
            commit('initWordsFilters', filters || state.wordsFilters)
          })
      }
    },
    initCategories: ({ commit, state }, pagination = false) => {
      if (!state.categories.data.length || !isEqual(pagination, state.categoriesPagination)) {
        const { page, itemsPerPage, sortBy: [sortBy], sortDesc: [sortDesc] } = pagination || state.categoriesPagination
        http
          .get('api/v1/categories', {
            params: {
              page, pageSize: itemsPerPage, sort: sortBy && `${sortDesc ? '-' : ''}${sortBy}`
            }
          })
          .then(response => response.data)
          .then(data => {
            commit('initCategories', data)
            commit('initCategoriesPagination', pagination || state.categoriesPagination)
          })
      }
    },
    initCategoriesOptions: ({ commit, state }, force = false) => {
      if (!state.categoriesOptions.length || force) {
        http
          .get('api/v1/categories/all')
          .then(response => response.data.data)
          .then(data => commit('initCategoriesOptions', data))
      }
    },
    initAdmins: ({ commit, state }, pagination = false) => {
      if (!state.admins.data.length || !isEqual(pagination, state.adminsPagination)) {
        const { page, itemsPerPage, sortBy: [sortBy], sortDesc: [sortDesc] } = pagination || state.adminsPagination
        http
          .get('api/v1/admins', {
            params: {
              page, pageSize: itemsPerPage, sort: sortBy && `${sortDesc ? '-' : ''}${sortBy}`
            }
          })
          .then(response => response.data)
          .then(data => {
            commit('initAdmins', data)
            commit('initAdminsPagination', pagination || state.adminsPagination)
          })
      }
    },
    initFmPmWords: ({ commit, state }, text = state.searchForm.text) => {
      if (text) {
        return http
          .get('api/v1/words/search', {
            params: {
              text
            }
          })
          .then(response => response.data.data)
          .then(data => {
            commit('initFmWords', data.fm)
            commit('initPmWords', data.pm)
            commit('initSearchForm', { text })
          })
      } else {
        return Promise.resolve()
          .then(() => {
            commit('initFmWords', [])
            commit('initPmWords', [])
            commit('initSearchForm', { text: '' })
          })
      }
    },
    initUser: ({ commit, state }) => {
      if (!state.user) {
        http
          .get('api/v1/auth/me')
          .then(response => response.data.data)
          .then(data => commit('initUser', data))
      }
    },
    pushMessage: ({ commit }, data) => {
      const id = Date.now()
      commit('addMessage', { ...data, id })
      setTimeout(() => commit('delMessage', id), 3000)
    }
  }
})

export default store
