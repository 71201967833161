<template>
  <v-flex
    fill-height
    md12
  >
    <v-card height="100%">
      <v-card-text>
        <v-text-field
          v-model.trim="text"
          placeholder="Enter a word..."
          solo
          type="text"
          @keyup.enter="onEnterTextField()"
          @input="onInputTextField()"
        >
          <template v-slot:append>
            <v-btn
              :disabled="!isSuperadmin"
              icon
              @click="switchDownloadDialog()"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn
              :disabled="!isSuperadmin"
              icon
              @click="switchUploadDialog()"
            >
              <v-icon>mdi-upload</v-icon>
            </v-btn>
            <v-btn
              :disabled="!isSuperadmin"
              icon
              @click="switchCreateDialog()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-text-field>

        <div v-if="fmWords.length || pmWords.length">
          <v-list
            v-if="fmWords.length"
            dense
            subheader
          >
            <v-subheader>Full matching word</v-subheader>
            <v-list-item
              v-for="word in fmWords"
              :key="word.text"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span>
                    {{ word.text }}
                  </span>
                  <v-tooltip
                    v-if="word.confirmed"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="green"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-check-circle
                      </v-icon>
                    </template>
                    <span>Word is confirmed</span>
                  </v-tooltip>
                </v-list-item-title>
                <v-list-item-subtitle
                  @copy.prevent="copy"
                  v-html="getTranslationsAsHtml(word)"
                />
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn
                  :disabled="!isSuperadmin"
                  icon
                  @click="switchUpdateDialog(word)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  :disabled="!isSuperadmin"
                  icon
                  @click="switchDeleteDialog(word)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>

          <v-divider v-if="fmWords.length && pmWords.length" />

          <v-list
            v-if="pmWords.length"
            dense
            subheader
          >
            <v-subheader>Partial matching words</v-subheader>
            <v-list-item
              v-for="word in pmWords"
              :key="word.text"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <span>
                    {{ word.text }}
                  </span>
                  <v-tooltip
                    v-if="word.confirmed"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="green"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-check-circle
                      </v-icon>
                    </template>
                    <span>Word is confirmed</span>
                  </v-tooltip>
                </v-list-item-title>
                <v-list-item-subtitle
                  @copy.prevent="copy"
                  v-html="getTranslationsAsHtml(word)"
                />
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn
                  :disabled="!isSuperadmin"
                  icon
                  @click="switchUpdateDialog(word)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  :disabled="!isSuperadmin"
                  icon
                  @click="switchDeleteDialog(word)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </div>

        <div
          v-if="!fmWords.length && !pmWords.length"
          class="text-center"
        >
          Words not found...
        </div>
      </v-card-text>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <download-dialog
      v-if="isSuperadmin"
      :show="dialogs.download"
      @cancel="switchDownloadDialog()"
      @submit="switchDownloadDialog()"
    />
    <upload-dialog
      v-if="isSuperadmin"
      :show="dialogs.upload"
      @cancel="switchUploadDialog()"
      @submit="switchUploadDialogAndInit()"
    />
    <create-dialog
      v-if="isSuperadmin"
      :show="dialogs.create"
      @cancel="switchCreateDialog()"
      @submit="switchCreateDialogAndInit()"
    />
    <update-dialog
      v-if="isSuperadmin"
      :show="dialogs.update"
      :item="updatedWord"
      @cancel="switchUpdateDialog()"
      @submit="switchUpdateDialogAndInit()"
    />
    <delete-dialog
      v-if="isSuperadmin"
      :show="dialogs.delete"
      :item="deletedWord"
      @cancel="switchDeleteDialog()"
      @submit="switchDeleteDialogAndInit()"
    />
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CreateDialog from './words/CreateDialog'
import DeleteDialog from './words/DeleteDialog'
import DownloadDialog from './words/DownloadDialog'
import UpdateDialog from './words/UpdateDialog'
import UploadDialog from './words/UploadDialog'

export default {
  name: 'Home',
  components: {
    'download-dialog': DownloadDialog,
    'upload-dialog': UploadDialog,
    'create-dialog': CreateDialog,
    'update-dialog': UpdateDialog,
    'delete-dialog': DeleteDialog
  },
  data() {
    return {
      text: '',
      overlay: false,
      dialogs: {
        download: false,
        upload: false,
        create: false,
        update: false,
        delete: false
      },
      updatedWord: {},
      deletedWord: {}
    }
  },
  computed: {
    isSuperadmin() {
      return this.user && this.user.role === 'superadmin'
    },
    ...mapGetters([
      'fmWords',
      'pmWords',
      'searchForm',
      'user'
    ])
  },
  created() {
    this.text = this.searchForm.text
    this.initCategoriesOptions()
  },
  methods: {
    copy(event) {
      const text = document.getSelection().toString()
      const clipdata = event.clipboardData || window.clipboardData
      clipdata.setData('text/plain', text)
    },
    getTranslationsAsHtml(word) {
      return word.translations.map(translation => (typeof translation === 'object'
        ? `${translation.text} <i>[${translation.note}]</i>`
        : translation)).join('; ')
    },
    onEnterTextField() {
      if (this.text.length >= 2) {
        this.overlay = true
        this.initFmPmWords(this.text)
          .finally(() => (this.overlay = false))
      }
    },
    onInputTextField() {
      if (this.text.length <= 0) {
        this.overlay = true
        this.initFmPmWords(this.text)
          .finally(() => (this.overlay = false))
      }
    },
    init() {
      this.initFmPmWords(this.text)
      this.initWords()
    },
    switchDownloadDialog() {
      this.dialogs.download = !this.dialogs.download
    },
    switchUploadDialog() {
      this.dialogs.upload = !this.dialogs.upload
    },
    switchCreateDialog() {
      this.dialogs.create = !this.dialogs.create
    },
    switchUpdateDialog(word = {}) {
      this.dialogs.update = !this.dialogs.update
      this.updatedWord = word
    },
    switchDeleteDialog(word = {}) {
      this.dialogs.delete = !this.dialogs.delete
      this.deletedWord = word
    },
    switchUploadDialogAndInit() {
      this.switchUploadDialog()
      this.init()
    },
    switchCreateDialogAndInit() {
      this.switchCreateDialog()
      this.init()
    },
    switchUpdateDialogAndInit() {
      this.switchUpdateDialog()
      this.init()
    },
    switchDeleteDialogAndInit() {
      this.switchDeleteDialog()
      this.init()
    },
    ...mapActions([
      'initWords',
      'initFmPmWords',
      'initCategoriesOptions'
    ])
  }
}
</script>
