<template>
  <v-dialog
    v-model="show"
    width="800px"
    @click:outside="cancel()"
    @keydown.esc="cancel()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Word updating</span>
      </v-card-title>
      <v-card-text>
        <validation-observer ref="form">
          <v-form>
            <validation-provider name="category_id">
              <v-select
                v-model="model.category_id"
                clearable
                item-text="text"
                item-value="id"
                :items="categoriesOptions"
                label="Category"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="text"
              rules="required|max:100"
            >
              <v-text-field
                v-model="model.text"
                :error-messages="errors[0]"
                label="Text"
                type="text"
              />
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="translation_text"
              :rules="`${model.translations && !model.translations.length ? 'required' : ''}|translation:125,255`"
            >
              <v-text-field
                ref="translation_text"
                v-model="translation_text"
                :error-messages="errors[0]"
                label="Translation(s)"
                type="text"
                @keyup.enter="createTranslationsItem()"
              />
              <v-chip-group
                class="px-1"
                column
              >
                <v-chip
                  v-for="translation in model.translations"
                  :key="typeof translation === 'object' ? translation.text : translation"
                  close
                  @click="updateTranslationsItem(translation)"
                  @click:close="deleteTranslationsItem(translation)"
                >
                  <div
                    class="text-truncate"
                    v-html="getHtmlFromTranslation(translation)"
                  />
                </v-chip>
              </v-chip-group>
            </validation-provider>

            <validation-provider name="confirmed">
              <v-checkbox
                v-model="model.confirmed"
                label="Is confirmed?"
              />
            </validation-provider>
          </v-form>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel()">
          Cancel
        </v-btn>
        <v-btn @click="submit()">
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'

import http from '../../../services/http'

export default {
  name: 'UpdateDialog',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider
  },
  props: {
    show: Boolean,
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      translation_text: undefined,
      id: undefined,
      model: {
        category_id: undefined,
        text: undefined,
        translations: [],
        confirmed: false
      }
    }
  },
  computed: {
    ...mapGetters([
      'categoriesOptions'
    ])
  },
  watch: {
    item(value) {
      this.id = value.id ?? undefined
      this.model = {
        category_id: value.category_id ?? undefined,
        text: value.text ?? undefined,
        translations: value.translations ? [...value.translations] : [],
        confirmed: Boolean(value.confirmed)
      }
    }
  },
  methods: {
    getHtmlFromTranslation(translation) {
      return typeof translation === 'object' ? `${translation.text} <i>[${translation.note}]</i>` : translation
    },
    getTranslationFromText(translation) {
      const match = translation.trim().match(/^([^[\]]+)( \[(.+)\])?$/)
      if (!match) return undefined
      const text = match[1].trim()
      const note = typeof match[3] == 'string' ? match[3].trim() : match[3]
      return note ? { text, note } : text
    },
    createTranslationsItem() {
      if (!this.$refs.form.fields.translation_text.valid) return
      const translation = this.getTranslationFromText(this.translation_text)
      if (!translation) return
      const text = typeof translation === 'object' ? translation.text : translation
      if (this.model.translations.some(item => (typeof item === 'object' ? item.text : item) === text)) return
      this.model.translations.push(translation)
      this.translation_text = undefined
    },
    updateTranslationsItem(translation) {
      this.translation_text = typeof translation === 'object' ? `${translation.text} [${translation.note}]` : translation
      const text = typeof translation === 'object' ? translation.text : translation
      this.model.translations.splice(this.model.translations.findIndex(item => (typeof item === 'object' ? item.text : item) === text), 1)
      const tmp_text = this.translation_text
      this.$refs.translation_text.$emit('input')
      this.translation_text = tmp_text
    },
    deleteTranslationsItem(translation) {
      const text = typeof translation === 'object' ? translation.text : translation
      this.model.translations.splice(this.model.translations.findIndex(item => (typeof item === 'object' ? item.text : item) === text), 1)
      const tmp_text = this.translation_text
      this.$refs.translation_text.$emit('input')
      this.translation_text = tmp_text
    },
    reset(event) {
      this.$emit(event)
      Object.assign(this.$data, this.$options.data.apply(this))
      this.$refs.form.reset()
    },
    cancel() {
      this.reset('cancel')
    },
    submit() {
      if (this.translation_text) this.createTranslationsItem()
      this.$refs.form.validate().then(isValid => {
        if (isValid) {
          if (!this.$refs.form.flags.dirty) return this.cancel()
          http.put(`api/v1/words/${this.id}`, this.model)
            .then(
              () => {
                this.pushMessage({
                  text: 'Word was updated successfully',
                  color: 'green'
                })
                this.reset('submit')
              },
              error => {
                this.pushMessage({
                  text: error.response.data.message || 'Failed to update word',
                  color: 'red'
                })
              }
            )
        }
      })
    },
    ...mapActions([
      'pushMessage',
    ])
  }
}
</script>
