<template>
  <v-dialog
    v-model="show"
    width="800px"
    @click:outside="cancel()"
    @keydown.esc="cancel()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Category updating</span>
      </v-card-title>
      <v-card-text>
        <validation-observer ref="form">
          <v-form>
            <validation-provider
              v-slot="{ errors }"
              name="text"
              rules="required|max:100"
            >
              <v-text-field
                v-model="model.text"
                :error-messages="errors[0]"
                label="Text"
                type="text"
              />
            </validation-provider>
          </v-form>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="cancel()">
          Cancel
        </v-btn>
        <v-btn @click="submit()">
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapActions } from 'vuex'

import http from '../../../services/http'

export default {
  name: 'UpdateDialog',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider
  },
  props: {
    show: Boolean,
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      id: undefined,
      model: {
        text: undefined
      }
    }
  },
  watch: {
    item(value) {
      this.id = value.id ?? undefined
      this.model = {
        text: value.text ?? undefined
      }
    }
  },
  methods: {
    reset(event) {
      this.$emit(event)
      Object.assign(this.$data, this.$options.data.apply(this))
      this.$refs.form.reset()
    },
    cancel() {
      this.reset('cancel')
    },
    submit() {
      this.$refs.form.validate().then(isValid => {
        if (isValid) {
          if (!this.$refs.form.flags.dirty) return this.cancel()
          http.put(`api/v1/categories/${this.id}`, this.model)
            .then(
              () => {
                this.pushMessage({
                  text: 'Category was updated successfully',
                  color: 'green'
                })
                this.reset('submit')
              },
              error => {
                this.pushMessage({
                  text: error.response.data.message || 'Failed to update category',
                  color: 'red'
                })
              }
            )
        }
      })
    },
    ...mapActions([
      'pushMessage',
    ])
  }
}
</script>
