<template>
  <v-app>
    <v-navigation-drawer
      app
      clipped
      :value="drawer"
    >
      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="toLink(item.to)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
    >
      <v-app-bar-nav-icon @click="switchDrawer()" />
      <v-toolbar-title
        style="cursor: pointer"
        @click="toLink('/')"
      >
        Sexologist Dictionary App
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-title class="text-subtitle-1">
        {{ user && user.username }}
      </v-toolbar-title>
      <v-btn
        icon
        @click="logout()"
      >
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container
        align-content-start
        fill-height
        fluid
      >
        <router-view />
      </v-container>
    </v-main>

    <v-footer app>
      <div>&copy; {{ new Date().getFullYear() }}</div>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Main',
  data() {
    return {
      items: [
        { title: 'Words', to: '/words', icon: 'mdi-format-text' },
        { title: 'Categories', to: '/categories', icon: 'mdi-tag' },
        { title: 'Admins', to: '/admins', icon: 'mdi-account' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'drawer',
      'user'
    ])
  },
  created() {
    this.initUser()
  },
  methods: {
    toLink(link) {
      if (this.$route.path !== link) {
        this.$router.push(link).catch(error => console.error(error)) // eslint-disable-line no-console
      }
    },
    logout() {
      this.pushMessage({
        text: 'Logout succeeded',
        color: 'success'
      })
      this.reset()
      localStorage.clear()
      this.$router.push('/login').catch(error => console.error(error)) // eslint-disable-line no-console
    },
    ...mapMutations([
      'switchDrawer',
      'reset'
    ]),
    ...mapActions([
      'initUser',
      'pushMessage'
    ])
  }
}
</script>
