import { extend } from 'vee-validate'
import { email, max, mimes, min, numeric, required } from 'vee-validate/dist/rules'

extend('email', { ...email, message: 'The {_field_} field must be a valid email.' })
extend('included', {
  validate(value, { options }) {
    return Array.isArray(options) && options.includes(value)
  },
  params: ['options'],
  message: 'The {_field_} field must be a valid value.'
})
extend('max', { ...max, message: 'The {_field_} field may not be greater than {length} characters.' })
extend('mimes', { ...mimes, message: 'The {_field_} field must contain a valid file.' })
extend('min', { ...min, message: 'The {_field_} field may not be less than {length} characters.' })
extend('numeric', { ...numeric, message: 'The {_field_} field must be a numeric.' })
extend('required', { ...required, message: 'The {_field_} field is required.' })
extend('translation', {
  validate(value, { maxText, maxNote }) {
    const match = value.trim().match(/^([^[\]]+)( \[(.+)\])?$/)
    if (!match) return 'The {_field_} field must be a valid translation.'
    const text = match[1].trim()
    const note = typeof match[3] == 'string' ? match[3].trim() : match[3]
    if (!text) return 'The translation text is required.'
    if (text.length > maxText) return 'The translation text may not be greater than {maxText} characters.'
    if (note && note.length > maxNote) return 'The translation note may not be greater than {maxNote} characters.'
    return true
  },
  params: ['maxText', 'maxNote']
})
