<template>
  <v-flex md12>
    <v-data-table
      dense
      :footer-props="{
        'items-per-page-options': [5, 10, 15]
      }"
      :headers="headers"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      @update:options="onUpdateOptions()"
    >
      <template v-slot:top>
        <v-toolbar
          dense
          flat
        >
          <v-toolbar-title>Categories</v-toolbar-title>
          <v-spacer />
          <v-btn
            :disabled="!isSuperadmin"
            icon
            @click.stop="switchCreateDialog()"
          >
            <v-icon small>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at ? new Date(item.created_at).toLocaleString() : '-' }}
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ item.updated_at ? new Date(item.updated_at).toLocaleString() : '-' }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          :disabled="!isSuperadmin"
          icon
          @click.stop="switchUpdateDialog(item)"
        >
          <v-icon small>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          :disabled="!isSuperadmin"
          icon
          @click.stop="switchDeleteDialog(item)"
        >
          <v-icon small>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <create-dialog
      v-if="isSuperadmin"
      :show="dialogs.create"
      @cancel="switchCreateDialog()"
      @submit="switchCreateDialogAndInit()"
    />
    <update-dialog
      v-if="isSuperadmin"
      :show="dialogs.update"
      :item="updatedItem"
      @cancel="switchUpdateDialog()"
      @submit="switchUpdateDialogAndInit()"
    />
    <delete-dialog
      v-if="isSuperadmin"
      :show="dialogs.delete"
      :item="deletedItem"
      @cancel="switchDeleteDialog()"
      @submit="switchDeleteDialogAndInit()"
    />
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CreateDialog from './categories/CreateDialog'
import DeleteDialog from './categories/DeleteDialog'
import UpdateDialog from './categories/UpdateDialog'

export default {
  name: 'Categories',
  components: {
    'create-dialog': CreateDialog,
    'update-dialog': UpdateDialog,
    'delete-dialog': DeleteDialog
  },
  data() {
    return {
      headers: [
        { text: '#', value: 'id', width: '60px' },
        { text: 'Text', value: 'text' },
        { text: 'Created at', value: 'created_at', width: '180px' },
        { text: 'Updated at', value: 'updated_at', width: '180px' },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false, width: '105px' }
      ],
      pagination: {},
      dialogs: {
        create: false,
        update: false,
        delete: false
      },
      updatedItem: {},
      deletedItem: {}
    }
  },
  computed: {
    isSuperadmin() {
      return this.user && this.user.role === 'superadmin'
    },
    ...mapGetters({
      items: 'categoriesItems',
      totalItems: 'categoriesTotalItems',
      paginationState: 'categoriesPagination',
      user: 'user'
    })
  },
  created() {
    this.pagination = this.paginationState
  },
  methods: {
    onUpdateOptions() {
      this.initCategories(this.pagination)
    },
    init() {
      this.initCategories()
      this.initCategoriesOptions(true)
    },
    switchCreateDialog() {
      this.dialogs.create = !this.dialogs.create
    },
    switchUpdateDialog(item = {}) {
      this.dialogs.update = !this.dialogs.update
      this.updatedItem = item
    },
    switchDeleteDialog(item = {}) {
      this.dialogs.delete = !this.dialogs.delete
      this.deletedItem = item
    },
    switchCreateDialogAndInit() {
      this.switchCreateDialog()
      this.init()
    },
    switchUpdateDialogAndInit() {
      this.switchUpdateDialog()
      this.init()
    },
    switchDeleteDialogAndInit() {
      this.switchDeleteDialog()
      this.init()
    },
    ...mapActions([
      'initCategories',
      'initCategoriesOptions'
    ])
  }
}
</script>
