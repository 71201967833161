import Vue from 'vue'
import VueRouter from 'vue-router'

import Admins from '../views/main/Admins.vue'
import Categories from '../views/main/Categories.vue'
import Home from '../views/main/Home.vue'
import Words from '../views/main/Words.vue'
import Login from '../views/Login.vue'
import Main from '../views/Main.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Main,
      meta: { auth: true },
      children: [
        { path: '', component: Home },
        { path: 'words', component: Words },
        { path: 'categories', component: Categories },
        { path: 'admins', component: Admins }
      ]
    },
    { path: '/login', component: Login },
    { path: '*', component: NotFound }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.auth)) {
    const token = localStorage.getItem('token')
    if (!token) {
      next({ path: '/login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
