import axios from 'axios'

import router from '../router'
import config from '../../config'

const http = axios.create({
  baseURL: config.baseURL
})

http.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      localStorage.clear()
      if (router.currentRoute.path !== '/login') {
        router.push('/login').catch(error => console.error(error)) // eslint-disable-line no-console
      }
    }
    return Promise.reject(error)
  }
)

export default http
