var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{attrs:{"md12":""}},[_c('v-data-table',{attrs:{"dense":"","footer-props":{
      'items-per-page-options': [5, 10, 15]
    },"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.totalItems},on:{"update:options":[function($event){_vm.pagination=$event},function($event){return _vm.onUpdateOptions()}]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Categories")]),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.isSuperadmin,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.switchCreateDialog()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1)],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at ? new Date(item.created_at).toLocaleString() : '-')+" ")]}},{key:"item.updated_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.updated_at ? new Date(item.updated_at).toLocaleString() : '-')+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!_vm.isSuperadmin,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.switchUpdateDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"disabled":!_vm.isSuperadmin,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.switchDeleteDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)}),(_vm.isSuperadmin)?_c('create-dialog',{attrs:{"show":_vm.dialogs.create},on:{"cancel":function($event){return _vm.switchCreateDialog()},"submit":function($event){return _vm.switchCreateDialogAndInit()}}}):_vm._e(),(_vm.isSuperadmin)?_c('update-dialog',{attrs:{"show":_vm.dialogs.update,"item":_vm.updatedItem},on:{"cancel":function($event){return _vm.switchUpdateDialog()},"submit":function($event){return _vm.switchUpdateDialogAndInit()}}}):_vm._e(),(_vm.isSuperadmin)?_c('delete-dialog',{attrs:{"show":_vm.dialogs.delete,"item":_vm.deletedItem},on:{"cancel":function($event){return _vm.switchDeleteDialog()},"submit":function($event){return _vm.switchDeleteDialogAndInit()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }