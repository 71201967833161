<template>
  <v-app>
    <v-main>
      <v-container
        fill-height
        fluid
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            md4
            shrink
          >
            <h1 class="text-md-center">
              404 Page Not Found
            </h1>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
