<template>
  <v-flex md12>
    <v-data-table
      class="words-data-table"
      dense
      :footer-props="{
        'items-per-page-options': [5, 10, 15]
      }"
      :headers="headers"
      :items="items"
      :options.sync="pagination"
      :server-items-length="totalItems"
      @update:options="onUpdateOptions()"
    >
      <template v-slot:top>
        <v-toolbar
          dense
          flat
        >
          <v-toolbar-title>Words</v-toolbar-title>
          <v-spacer />
          <v-btn
            :disabled="!isSuperadmin"
            icon
            @click.stop="switchCreateDialog()"
          >
            <v-icon small>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:header.confirmed="{ header }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ header.text }}
            </span>
          </template>
          <span>Is confirmed?</span>
        </v-tooltip>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td />
          <td>
            <v-text-field
              v-model="filters['filters[text__like]']"
              dense
              hide-details
              @keyup.enter="onEnterTextField('filters[text__like]')"
              @input="onInputTextField('filters[text__like]')"
            />
          </td>
          <td>
            <v-text-field
              v-model="filters['filters[translations__like]']"
              dense
              hide-details
              @keyup.enter="onEnterTextField('filters[translations__like]')"
              @input="onInputTextField('filters[translations__like]')"
            />
          </td>
          <td>
            <v-select
              v-model="filters['filters[category_id]']"
              clearable
              dense
              hide-details
              item-text="text"
              item-value="id"
              :items="categoriesOptions"
              @change="search()"
            />
          </td>
          <td>
            <v-select
              v-model="filters['filters[confirmed]']"
              clearable
              dense
              hide-details
              item-text="text"
              item-value="value"
              :items="confirmedOptions"
              @change="search()"
            />
          </td>
          <td>
            <v-btn
              icon
              @click.stop="search()"
            >
              <v-icon small>
                mdi-magnify
              </v-icon>
            </v-btn>
            <v-btn
              icon
              @click.stop="search(true)"
            >
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:[`item.translations`]="{ item }">
        <div
          @copy.prevent="copy"
          v-html="getTranslationsAsHtml(item)"
        />
      </template>
      <template v-slot:[`item.category`]="{ item }">
        <div>{{ getCategoryText(item) }}</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          :disabled="!isSuperadmin"
          icon
          @click.stop="switchUpdateDialog(item)"
        >
          <v-icon small>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          :disabled="!isSuperadmin"
          icon
          @click.stop="switchDeleteDialog(item)"
        >
          <v-icon small>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <create-dialog
      v-if="isSuperadmin"
      :show="dialogs.create"
      @cancel="switchCreateDialog()"
      @submit="switchCreateDialogAndInit()"
    />
    <update-dialog
      v-if="isSuperadmin"
      :show="dialogs.update"
      :item="updatedItem"
      @cancel="switchUpdateDialog()"
      @submit="switchUpdateDialogAndInit()"
    />
    <delete-dialog
      v-if="isSuperadmin"
      :show="dialogs.delete"
      :item="deletedItem"
      @cancel="switchDeleteDialog()"
      @submit="switchDeleteDialogAndInit()"
    />
  </v-flex>
</template>

<script>
import { pickBy } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import CreateDialog from './words/CreateDialog'
import DeleteDialog from './words/DeleteDialog'
import UpdateDialog from './words/UpdateDialog'

export default {
  name: 'Words',
  components: {
    'create-dialog': CreateDialog,
    'update-dialog': UpdateDialog,
    'delete-dialog': DeleteDialog
  },
  data() {
    return {
      headers: [
        { text: '#', value: 'id', width: '60px' },
        { text: 'Text', value: 'text', width: '25%' },
        { text: 'Translation(s)', value: 'translations', sortable: false },
        { text: 'Category', value: 'category', sortable: false, width: '240px' },
        { text: '?', value: 'confirmed', width: '105px' },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false, width: '105px' }
      ],
      pagination: {},
      filters: {},
      confirmedOptions: [
        { text: '0', value: 'false' },
        { text: '1', value: 'true' }
      ],
      dialogs: {
        create: false,
        update: false,
        delete: false
      },
      updatedItem: {},
      deletedItem: {}
    }
  },
  computed: {
    isSuperadmin() {
      return this.user && this.user.role === 'superadmin'
    },
    ...mapGetters({
      items: 'wordsItems',
      totalItems: 'wordsTotalItems',
      paginationState: 'wordsPagination',
      filtersState: 'wordsFilters',
      categoriesOptions: 'categoriesOptions',
      user: 'user'
    })
  },
  created() {
    this.pagination = this.paginationState
    this.filters = {
      ...this.filtersState
    }
    this.initCategoriesOptions()
  },
  methods: {
    copy(event) {
      const text = document.getSelection().toString()
      const clipdata = event.clipboardData || window.clipboardData
      clipdata.setData('text/plain', text)
    },
    getTranslationsAsHtml(word) {
      return word.translations.map(translation => (typeof translation === 'object'
        ? `${translation.text} <i>[${translation.note}]</i>`
        : translation)).join('; ')
    },
    getCategoryText(word) {
      return word.category ? word.category.text : '-'
    },
    onEnterTextField(field) {
      if (this.filters[field]?.length >= 1) this.search()
    },
    onInputTextField(field) {
      if (this.filters[field]?.length <= 0) this.search()
    },
    search(clear = false) {
      this.filters = !clear ? pickBy(this.filters) : {}
      if (this.pagination.page === 1) {
        this.initWords({ pagination: this.pagination, filters: this.filters })
      } else {
        this.pagination.page = 1
      }
    },
    onUpdateOptions() {
      this.initWords({ pagination: this.pagination, filters: this.filters })
    },
    init() {
      this.initFmPmWords()
      this.initWords()
    },
    switchCreateDialog() {
      this.dialogs.create = !this.dialogs.create
    },
    switchUpdateDialog(item = {}) {
      this.dialogs.update = !this.dialogs.update
      this.updatedItem = item
    },
    switchDeleteDialog(item = {}) {
      this.dialogs.delete = !this.dialogs.delete
      this.deletedItem = item
    },
    switchCreateDialogAndInit() {
      this.switchCreateDialog()
      this.init()
    },
    switchUpdateDialogAndInit() {
      this.switchUpdateDialog()
      this.init()
    },
    switchDeleteDialogAndInit() {
      this.switchDeleteDialog()
      this.init()
    },
    ...mapActions([
      'initWords',
      'initFmPmWords',
      'initCategoriesOptions'
    ])
  }
}
</script>

<style lang="sass">
.words-data-table
  .v-select__slot,
  .v-text-field__slot
    font-size: 0.875rem
</style>
