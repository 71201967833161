<template>
  <v-dialog
    v-model="show"
    width="400px"
    @click:outside="cancel()"
    @keydown.esc="cancel()"
  >
    <v-card>
      <v-card-title class="justify-center">
        <span class="headline">Dictionary Downloading</span>
      </v-card-title>
      <v-card-text class="text-center">
        <span>Are you sure want to download dictionary in Excel?</span>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="cancel()">
          Cancel
        </v-btn>
        <v-btn @click="submit()">
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'

import http from '../../../services/http'

export default {
  name: 'DownloadDialog',
  props: {
    show: Boolean
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    submit() {
      http.post('api/v1/words/download', {}, { responseType: 'blob' })
        .then(
          response => {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(new Blob([response.data]))
            link.download = `Словарь (${moment().format('YYYY.MM.DD')}).xlsx`
            link.click()
            this.$emit('submit')
          },
          error => {
            this.pushMessage({
              text: error.response.data.message || 'Failed to download dictionary',
              color: 'red'
            })
          }
        )
    },
    ...mapActions([
      'pushMessage',
    ])
  }
}
</script>
